<template>
<div id="sidenav">
  <profileBox :user="artist" />
  <portal v-bind:to="menuOpen">
    <followBox v-if="this.isAuthenticated" />
  </portal>
  <portal-target name="desktopFilterPosts"></portal-target>
</div>
</template>

<script>
import { mapState } from 'vuex'
import profileBox from '~/components/profile-box'
import followBox from '~/components/follow-box'

export default {
  components: {
    profileBox,
    followBox
  },

  computed: {
    ...mapState(['artist', 'isAuthenticated', 'mobileMenuOpen']),
    menuOpen() {
      return this.mobileMenuOpen ? 'mobileFollowship' : 'desktopFollowship'
    }
  }
}
</script>
