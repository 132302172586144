<template>
  <div class="social-icons-wrapper">
    <ul class="icon-holder">
      <li v-for="(acc, index) in activeSocials" v-bind:key="acc.name" v-bind:index="index">
        <a target="_blank" rel="nofollow" v-bind:href=user.socialAccounts[acc].account v-bind:class="`icon-${isMobileView ? 'sm' : 'lg' } icon-${acc !== 'website' ? `${acc} fab` : 'globe fas'}`"></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['user', 'activeSocials']
}
</script>
<style lang="scss">
  @import 'assets/sass/icons';
</style>
