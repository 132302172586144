<template>
  <div>
    <article id="profilebox" class="box">
      <figure @click.prevent="pushToProfile" class="image pb-15 clickable" :class="{
        'avatar-placeholder': !profileImage,
        'hide-on-mobile': !(user.profile.configuration || {})
          .displayAvatarMobile
      }">
        <img v-if="profileImage" :src="profileImage" />
      </figure>
      <p class="has-text-weight-semibold has-text-primary text-align-center">
        <span class="fitty">{{ user.nickname }}</span>
      </p>
      <div class="notification-controls">
        <followButton v-if="user.artist" :user="user" :fullWidth="!artist.followed" :withIcon="true"
          :withUnfollow="true" class="follow-btn is-fullwidth" />
        <notification-button v-if="artist.followed && artist.notifications" id="notification-btn" :class="{
          'on-bright-background': bodyBackgroundTextColor === '#000'
        }" />
      </div>
      <profile-social v-if="activeSocials.length > 0" :user="user" :activeSocials="activeSocials" />
      <div :class="{ 'is-hidden': !isMobileView }" class="content profileDescription is-mobile-profileDescription">
        <no-ssr>
          <div v-if="artist.profile.description">
            <read-more v-if="artist.profile.description.length > 180" :more-str="$t('moreBracket')"
              :less-str="$t('lessBracket')" :text="artist.profile.description" :max-chars="140"></read-more>
            <div v-else v-html="artist.profile.description"></div>
          </div>
        </no-ssr>
      </div>
    </article>
    <div v-if="user.artist" class="legal has-text-centered mb-25 mt-0">
      <button v-show="!isMobileView" id="imprint-btn" class="button is-outline is-small has-border-bottom"
        @click="pushToImprint">
        <span>{{ $t('imprint') }}</span>
      </button>
      <button v-if="hasTOS" id="tos-btn" class="button is-outline is-small has-border-bottom" @click="pushToTOS">
        <span>{{ $t('tos') }}</span>
      </button>
      <button v-if="hasPrivacy" id="privacy-btn" class="button is-outline is-small has-border-bottom"
        @click="pushToPrivacy">
        <span>{{ $t('privacy') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import fitty from 'fitty'
import profileSocial from '~/components/profile-social'
import followButton from '~/components/follow-button'
import notificationButton from '~/components/notification-button'

export default {
  props: ['user'],
  components: { profileSocial, followButton, notificationButton },
  data() {
    return {
      avatarWidths: {
        sm: 452,
        md: 452,
        lg: 182,
        lgr: 230,
        xl: 278,
        xxl: 278
      },
      dynamicScope: null,
      observer: null,
      bodyBackgroundTextColor: ''
    }
  },
  computed: {
    activeSocials() {
      return Object.keys(this.user.socialAccounts).filter(
        k => this.user.socialAccounts[k].enabled
      )
    },
    profileImage() {
      if (typeof this.user.profile.imgs.avatar === 'string') {
        return this.user.profile.imgs.avatar
      }
      if (!this.user.profile.imgs.avatar.name) {
        return ''
      }

      const width = this.avatarWidths[this.$mq] || this.avatarWidths.sm
      return `/services/media/image/${this.user._id}/${this.user.profile.imgs.avatar.name}/${width}/0/image.webp`
    },
    hasTOS() {
      return this.user.profile.configuration.legal.tos &&
        this.user.profile.configuration.legal.tos !== ''
    },
    hasPrivacy() {
      return this.user.profile.configuration.legal.privacy &&
        this.user.profile.configuration.legal.privacy !== ''
    },
    ...mapState(['isAuthenticated', 'artist'])
  },
  mounted() {
    this.dynamicScope = document.getElementById('dynamic-color-scope')
    fitty('.fitty', {
      minSize: 8,
      maxSize: 40,
      multiLine: true
    })

    // Create a MutationObserver to watch for changes in the element's style
    this.observer = new MutationObserver(() => {
      this.updateBodyBackgroundTextColor()
    })

    // Observe changes in the style attribute of the dynamicScope element
    this.observer.observe(this.dynamicScope, { attributes: true, attributeFilter: ['style'] })
    this.updateBodyBackgroundTextColor()
  },
  methods: {
    updateBodyBackgroundTextColor() {
      if (this.dynamicScope) {
        this.bodyBackgroundTextColor = getComputedStyle(this.dynamicScope).getPropertyValue('--body-background-text-color')
      }
    },
    async pushToImprint() {
      const locale = await this.$i18n.locale
      this.$router.push({
        name: `id-profile-imprint___${locale}`,
        params: {
          id: this.artist.urlAlias
        }
      })
    },
    async pushToTOS() {
      const locale = await this.$i18n.locale
      this.$router.push({
        name: `id-profile-tos___${locale}`,
        params: {
          id: this.artist.urlAlias
        }
      })
    },
    async pushToPrivacy() {
      const locale = await this.$i18n.locale
      this.$router.push({
        name: `id-profile-privacy___${locale}`,
        params: {
          id: this.artist.urlAlias
        }
      })
    },
    pushToProfile() {
      const path = this.user.artist
        ? `/${this.user.urlAlias}`
        : `/user/${this.user.urlAlias}`

      if (this.$route.path === path) {
        this.$scrollTo('#profile-navigation')
      }

      this.$router.push({ path: path })
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
</script>

<style lang="scss">
@import "assets/sass/_variables";

@media screen and (max-width: $mobile-portrait) {
  .hide-on-mobile {
    display: none;
  }

  .follow-btn {
    i {
      font-size: 0.8rem;
    }

    span {
      padding-left: 4px;
      font-size: 0.9rem;
    }
  }
}

#profilebox {
  figure {
    &.clickable {
      cursor: pointer;
    }
  }

  p {
    text-align: left;
    width: 100%;

    &.text-align-center {
      text-align: center;
    }

    .fitty {
      display: inline-block;
    }
  }

  border-radius: 0;

  .notification-controls {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    .follow-btn {
      max-width: 70%;

      &.is-fullwidth,
      &.full-width {
        width: 100%;
        max-width: 100%;
      }
    }

    #notification-btn {
      width: 100%;
    }
  }

  .avatar-placeholder {
    height: 250px;
    width: 100%;
    background-color: #ccc;
  }
}

.is-profilebox {
  box-shadow: none;
  border-radius: 0;
  line-height: 1.8rem;

  .has-description {
    margin-bottom: 1.5rem;
    padding: 0 0 1.25rem;
  }
}

.legal {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  align-content: space-between;
  flex-flow: column;

  button {
    width: 100%;
    margin-left: 0;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;

    &:first-child {
      margin-bottom: 5px;
      margin-right: 0px;
    }

    &:last-child {
      margin-left: 0 !important;
    }
  }
}
</style>
