<template>
  <button v-if="artist.followed && artist.notifications" @click="setNotification('email', !artist.notifications.email)"
    v-tooltip.bottom="{
      content: artist.notifications.email ? $t('mailNotificationDeactivate') : $t('mailNotificationActivate'),
      classes: ['notification-bell-tooltip']
    }" class="button tooltip notification-bell-button">
    <i :class="{
      'notification-bell-on notifications_active': artist.notifications.email === true
    }" class="material-icons">{{ artist.notifications.email === true ? 'notifications_on' : 'notifications_off' }}
    </i>
  </button>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['isAuthenticated', 'artist'])
  },
  methods: {
    ...mapActions(['setArtistNotification']),
    async setNotification(type, state) {
      if (this.isAuthenticated) {
        const data = await this.apiPost(`/api/private/${this.artist.urlAlias}/follow/notifications/${type}/${state}`, null, null)
        if (data && data.valid) {
          this.setArtistNotification({ type, state })
        } else {
          console.log('notification request invalid')
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import 'assets/sass/_variables';

.notification-bell-button {
  padding: 5px;

  .notification-bell {
    color: grey;

    &.notification-bell-on {
      color: var(--primary-color);
    }
  }
}

.tooltip {
  display: block !important;
}

.tooltip .tooltip-inner {
  font-size: 0.8rem;
  background: var(--primary-color);
  color: white;
  border-radius: 0px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: var(--primary-color);
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
