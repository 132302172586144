var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('article',{staticClass:"box",attrs:{"id":"profilebox"}},[_c('figure',{staticClass:"image pb-15 clickable",class:{
      'avatar-placeholder': !_vm.profileImage,
      'hide-on-mobile': !(_vm.user.profile.configuration || {})
        .displayAvatarMobile
    },on:{"click":function($event){$event.preventDefault();return _vm.pushToProfile.apply(null, arguments)}}},[(_vm.profileImage)?_c('img',{attrs:{"src":_vm.profileImage}}):_vm._e()]),_vm._v(" "),_c('p',{staticClass:"has-text-weight-semibold has-text-primary text-align-center"},[_c('span',{staticClass:"fitty"},[_vm._v(_vm._s(_vm.user.nickname))])]),_vm._v(" "),_c('div',{staticClass:"notification-controls"},[(_vm.user.artist)?_c('followButton',{staticClass:"follow-btn is-fullwidth",attrs:{"user":_vm.user,"fullWidth":!_vm.artist.followed,"withIcon":true,"withUnfollow":true}}):_vm._e(),_vm._v(" "),(_vm.artist.followed && _vm.artist.notifications)?_c('notification-button',{class:{
        'on-bright-background': _vm.bodyBackgroundTextColor === '#000'
      },attrs:{"id":"notification-btn"}}):_vm._e()],1),_vm._v(" "),(_vm.activeSocials.length > 0)?_c('profile-social',{attrs:{"user":_vm.user,"activeSocials":_vm.activeSocials}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content profileDescription is-mobile-profileDescription",class:{ 'is-hidden': !_vm.isMobileView }},[_c('no-ssr',[(_vm.artist.profile.description)?_c('div',[(_vm.artist.profile.description.length > 180)?_c('read-more',{attrs:{"more-str":_vm.$t('moreBracket'),"less-str":_vm.$t('lessBracket'),"text":_vm.artist.profile.description,"max-chars":140}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.artist.profile.description)}})],1):_vm._e()])],1)],1),_vm._v(" "),(_vm.user.artist)?_c('div',{staticClass:"legal has-text-centered mb-25 mt-0"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobileView),expression:"!isMobileView"}],staticClass:"button is-outline is-small has-border-bottom",attrs:{"id":"imprint-btn"},on:{"click":_vm.pushToImprint}},[_c('span',[_vm._v(_vm._s(_vm.$t('imprint')))])]),_vm._v(" "),(_vm.hasTOS)?_c('button',{staticClass:"button is-outline is-small has-border-bottom",attrs:{"id":"tos-btn"},on:{"click":_vm.pushToTOS}},[_c('span',[_vm._v(_vm._s(_vm.$t('tos')))])]):_vm._e(),_vm._v(" "),(_vm.hasPrivacy)?_c('button',{staticClass:"button is-outline is-small has-border-bottom",attrs:{"id":"privacy-btn"},on:{"click":_vm.pushToPrivacy}},[_c('span',[_vm._v(_vm._s(_vm.$t('privacy')))])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }